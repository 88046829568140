import store from "@/state/store";
import axios from "axios";
var state = store._modules.root._children.auth.state;
export default {
  install(Vue) {
    var self = Vue.config.globalProperties;
    Vue.config.globalProperties.http = {
      post(url, data, options) {
        return new Promise((resolve) => {
          // if (!options) options = {};
          // if (!options.headers) options.headers = {};
          // if (state.token) {
          //   options.headers["x-auth"] = state.token;
          //   data.user_id = state.currentUser.id;
          // }

          // if (localStorage.currentUser) {
          //   const currentUser = JSON.parse(localStorage.currentUser);
          //   data.user_id = currentUser?.id;
          // }
          // console.log(options);
          axios.post(state.baseUrl + url, data, options).then((resp) => {
            console.log(resp.headers["x-auth"]);

            // if (resp.data.status) {
            resolve(resp.data);
            // } else {
            // Vue.config.globalProperties.popup.alert({
            //   title: "popups.error",
            //   msg: resp.data.message,
            // });
            // }
          });
        });
      },
      do(url, data, options) {
        return new Promise((resolve) => {
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          if (state.token) {
            options.headers["x-auth"] = state.token;
            data.user_id = state.currentUser.id;
          }
          console.log(options);

          var formData = new FormData();
          for (var key in data) {
            formData.append(key, data[key]);
          }
          console.log(formData);
          axios.post(state.baseUrl + url, formData, options).then((resp) => {
            if (resp.data.status) {
              resolve(resp.data);
            } else {
              // Vue.config.globalProperties.popup.alert({
              //   title: "popups.error",
              //   msg: resp.data.message,
              // });
            }
          });
        });
      },
      put(url, id, data, options) {
        return new Promise((resolve) => {
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          if (state.token) {
            options.headers["x-auth"] = state.token;
            data.user_id = state.currentUser.id;
          }
          console.log(options);
          // data.lang = Vue.config.globalProperties.$i18n.locale
          axios
            .put(state.baseUrl + url + "/" + id, data, options)
            .then((resp) => {
              console.log(resp.headers["x-auth"]);
              if (resp.headers["x-auth"]) {
                state.token = resp.headers["x-auth"];
                console.log(data.pass);
                resp.data.data.pass = self.$e.encrypt(data.pass);
                state.currentUser = resp.data.data;
                localStorage.currentUser = JSON.stringify(resp.data.data);
                console.log(state.token);
              }
              if (resp.data.status) {
                resolve(resp.data);
              } else {
                // Vue.config.globalProperties.popup.alert({
                //   title: "popups.error",
                //   msg: resp.data.message,
                // });
              }
            });
        });
      },
      get(url) {
        return new Promise((resolve) => {
          axios.get(state.baseUrl + url).then((resp) => {
            console.log("the status is", resp.data.status);

            if (resp.data.status) {
              resolve(resp.data);
            } else {
              // Vue.config.globalProperties.popup.alert({
              //   title: "popups.error",
              //   msg: resp.data.message,
              // });
            }
          });
        });
      },
      delete(url, id, options) {
        return new Promise((resolve) => {
          var data = {};
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          if (state.token) {
            options.headers["x-auth"] = state.token;
            data.user_id = state.currentUser.id;
          }
          console.log(options);
          data.lang = Vue.config.globalProperties.$i18n.locale;
          axios
            .delete(
              state.baseUrl +
                url +
                "/" +
                id +
                "?" +
                new URLSearchParams(data).toString(),
              options
            )
            .then((resp) => {
              console.log(resp.headers["x-auth"]);
              if (resp.headers["x-auth"]) {
                state.token = resp.headers["x-auth"];
                console.log(data.pass);
                resp.data.data.pass = self.$e.encrypt(data.pass);
                state.currentUser = resp.data.data;
                localStorage.currentUser = JSON.stringify(resp.data.data);
                console.log(state.token);
              }
              if (resp.data.status) {
                resolve(resp.data);
              } else {
                // Vue.config.globalProperties.popup.alert({
                //   title: "popups.error",
                //   msg: resp.data.message,
                // });
              }
            });
        });
      },
    };
  },
};
