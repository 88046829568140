<template>
  <div class="row chat-wrapper">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row position-relative">
            <InboxSide
              @inbox_menu_item_tap="update_inboxes"
              @update_inboxes="loadInboxes"
              ref="InboxSide"
            />
            <inbox-contacts
              ref="InboxContacts"
              :inboxes_type="inboxes_type"
              :has_fillter_taps="has_fillter_taps"
              :tag_name="tag_name"
              @selected_inbox="show_inbox"
            />
            <ChatContent
              v-show="inbox != null"
              ref="ChatContent"
              :inbox="inbox"
              @update_inboxes="loadInboxes"
            />
            <div
              class="col-lg-7 chat-content align-items-center"
              v-show="inbox == null"
            >
              <div
                class="jumbotron d-flex align-items-center"
                style="height: 100%"
              >
                <div class="container text-center">
                  <img
                    src="/assets/images/send-message.png"
                    width="25%"
                    alt=""
                    srcset=""
                  />
                  <p>إختر إحدى المحادثات لبدء المراسلة</p>
                </div>
              </div>
            </div>
            <!-- <div class="col-lg-7 chat-content" v-show="inbox == null">
              <div class="container d-flex justify-content-center">
                <div class="align-items-center">
                  <img src="/assets/images/Chat-PNG.png" alt="" srcset="" />
                </div>
                <p>إختر إحدى المحادثات لبدء المراسلة</p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InboxSide from "./Inbox/InboxSide";
import InboxContacts from "./Inbox/InboxContacts";
import ChatContent from "./Inbox/ChatContent";

export default {
  name: "InboxView",
  components: {
    InboxSide,
    InboxContacts,
    ChatContent,
  },
  data() {
    return {
      inboxes_type: "new_inboxes",
      has_fillter_taps: false,
      tag_name: "جديد",
      inbox: null,
    };
  },

  methods: {
    update_inboxes(type) {
      console.log("colled");
      console.log(type);
      if (type == "new_inboxes") {
        this.tag_name = "جديد";
        this.has_fillter_taps = false;
      } else if (type == "assigned_inboxes") {
        this.tag_name = "تم تعيينه";
        this.has_fillter_taps = true;
      } else if (type == "finished_inboxes") {
        this.tag_name = "مغلق";
        this.has_fillter_taps = false;
      }
      this.inboxes_type = type;
      this.$refs.InboxContacts.getInboxes(type);
    },
    loadInboxes() {
      this.$refs.InboxContacts.loadInboxes();
    },
    async show_inbox(inbox) {
      this.inbox = inbox;
      await this.$nextTick();
      this.$refs.ChatContent.getMessages();
      this.$refs.ChatContent.labels = this.$refs.InboxSide.labels;
      this.$refs.ChatContent.users = this.$refs.InboxSide.users;
    },
  },
};
</script>

<style scoped>
.card {
  height: 100%;
}
</style>
