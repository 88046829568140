<template>
  <SideView />

  <div class="page-wrapper">
    <HeaderView />
    <div class="page-content">
      <SettingsView />
    </div>
    <FooterView />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderView from "@/components/HeaderView.vue";
import SideView from "@/components/SideView.vue";
import FooterView from "@/components/FooterView.vue";
import SettingsView from "@/components/SettingsView.vue";

export default {
  name: "SettingView",
  components: {
    HeaderView,
    SideView,
    FooterView,
    SettingsView,
  },
};
</script>

<style scoped></style>
