<template>
  <div class="col-md-6">
    <div class="">
      <div class="">
        <div class="row">
          <h6 class="card-title">
            <i class="btn-icon-prepend" data-feather="tag"></i> Lables
          </h6>
          <!-- <p class="text-muted mb-3">Add class <code>.table</code></p> -->
        </div>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th>NAME</th>
                <th>TAG_COLOR</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(label, index) in labels" :key="label">
                <td>{{ index + 1 }}</td>
                <td>{{ label.name }}</td>
                <td>
                  <div
                    class="alert alert-secondary"
                    :style="alertStyles(label.color)"
                    role="alert"
                  ></div>
                </td>
                <td>
                  <button type="button" class="btn btn-icon btn-primary">
                    <BIconPencilSquare />
                  </button>
                  ||
                  <button type="button" class="btn btn-danger btn-icon">
                    <BIconTrashFill />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Add New Label</h6>

        <form class="forms-sample" @submit.prevent="createLabel">
          <div class="mb-3">
            <label for="exampleInputUsername1" class="form-label">Name</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputUsername1"
              autocomplete="off"
              placeholder="label name"
              v-model="label.name"
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputUsername1" class="form-label">Color</label>
            <b-form-input
              id="type-color"
              type="color"
              v-model="label.color"
            ></b-form-input>
          </div>

          <button type="submit" class="btn btn-primary me-2">Save</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labels: [],
      label: {},
      loading: false,
    };
  },
  methods: {
    alertStyles(color) {
      return {
        "background-color": color + " !important",
        "border-color": color + " !important",
        opacity: 0.5,
      };
    },
    async getLabels() {
      console.log("getLabels");
      this.loading = true;

      this.http.get("settings/labels").then((res) => {
        console.log(res);
        this.loading = false;
        if (res.status) {
          this.labels = res.data;
        }
      });
    },

    async createLabel() {
      console.log(this.label);
      if (this.label.color == null) {
        return;
      }
      this.loading = true;
      this.http.post("settings/labels/create", this.label).then((res) => {
        this.loading = false;
        if (res.status) {
          this.label = {};
          this.getLabels();
        }
      });
    },
  },
  mounted() {
    console.log("mounted");
    this.getLabels();
  },
};
</script>
