<template>
  <div class="col-lg-2 col-md-4 chat-aside border-end-lg">
    <div class="aside-content">
      <div class="aside-header">
        <div class="justify-content-between align-items-center pb-2 mb-2">
          <div class="align-items-center">
            <div class="d-grid gap-2">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#newChatModal"
                data-bs-whatever="@mdo"
                class="btn btn-primary btn-icon-text"
              >
                Create Chat
                <i class="btn-icon-append" data-feather="edit"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="aside-body">
        <div class="tab-content mt-3">
          <div
            class="tab-pane fade show active"
            id="side"
            role="tabpanel"
            aria-labelledby="chats-tab"
          >
            <ul class="list-unstyled inbox-side-nav chat-list px-1">
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="collapse"
                  href="#inbox"
                  role="button"
                  aria-expanded="true"
                  aria-controls="inbox"
                >
                  <i class="link-icon" data-feather="chevron-down"></i>
                  <strong class="link-title">Inbox</strong>
                </a>
                <div class="collapse show" id="inbox">
                  <ul class="inbox-side-nav sub-menu">
                    <li class="nav-item">
                      <a
                        href="#"
                        class="nav-link"
                        :class="{ active: nav_item_selected == 'new_inboxes' }"
                        @click="nav_item_select('new_inboxes')"
                      >
                        <i class="link-icon" data-feather="inbox"></i>
                        <span class="link-title">جديد</span>
                        <span class="link-arrow"> {{ inboxes }} </span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        href="#"
                        class="nav-link"
                        :class="{
                          active: nav_item_selected == 'assigned_inboxes',
                        }"
                        @click="nav_item_select('assigned_inboxes')"
                      >
                        <i class="link-icon" data-feather="users"></i>
                        <span class="link-title">تم تعيينه</span>
                        <span class="link-arrow"> {{ assigneds }} </span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        href="#"
                        class="nav-link"
                        :class="{
                          active: nav_item_selected == 'finished_inboxes',
                        }"
                        @click="nav_item_select('finished_inboxes')"
                      >
                        <i class="link-icon" data-feather="check-square"></i>
                        <span class="link-title">مغلق</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a href="#" class="nav-link">
                        <i class="link-icon" data-feather="alert-circle"></i>
                        <span class="link-title">البريد المزعج</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link collapsed"
                  data-bs-toggle="collapse"
                  href="#personal"
                  role="button"
                  aria-expanded="true"
                  aria-controls="personal"
                >
                  <i class="link-icon" data-feather="chevron-down"></i>
                  <strong class="link-title">Personal</strong>
                </a>
                <div class="collapse show" id="personal">
                  <ul class="inbox-side-nav sub-menu">
                    <li class="nav-item">
                      <a href="pages/icons/feather-icons.html" class="nav-link">
                        <i class="link-icon" data-feather="user"></i>
                        <span class="link-title">تم تعيينه لي</span>
                        <span class="link-arrow"> 20 </span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="pages/icons/feather-icons.html" class="nav-link">
                        <i class="link-icon" data-feather="at-sign"></i>
                        <span class="link-title">ذكر</span>
                        <span class="link-arrow"> 20 </span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a href="pages/icons/feather-icons.html" class="nav-link">
                        <i class="link-icon" data-feather="star"></i>
                        <span class="link-title">مفضلة</span>
                        <span class="link-arrow"> 20 </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link collapsed"
                  data-bs-toggle="collapse"
                  href="#team-work"
                  role="button"
                  aria-expanded="false"
                  aria-controls="team-work"
                >
                  <i class="link-icon" data-feather="chevron-down"></i>
                  <strong class="link-title">Teams</strong>
                </a>
                <div class="collapse" id="team-work">
                  <ul class="inbox-side-nav sub-menu">
                    <li class="nav-item" v-for="team in teams" :key="team">
                      <a href="pages/icons/feather-icons.html" class="nav-link">
                        <BIconFolder />
                        <span class="link-title">{{ team.name }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="collapse"
                  href="#chanels"
                  role="button"
                  aria-expanded="false"
                  aria-controls="chanels"
                >
                  <i class="link-icon" data-feather="chevron-down"></i>
                  <strong class="link-title">Channels</strong>
                </a>
                <div class="collapse" id="chanels">
                  <ul class="inbox-side-nav sub-menu">
                    <li
                      class="nav-item"
                      v-for="channel in channels"
                      :key="channel"
                    >
                      <a href="pages/icons/feather-icons.html" class="nav-link">
                        <b-icon-whatsapp class="link-icon" style="fill: #000" />
                        <span class="link-title">{{ channel.name }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link collapsed"
                  data-bs-toggle="collapse"
                  href="#labels"
                  role="button"
                  aria-expanded="false"
                  aria-controls="labels"
                >
                  <i class="link-icon" data-feather="chevron-down"></i>
                  <strong class="link-title">Lables</strong>
                </a>
                <div class="collapse" id="labels">
                  <ul class="inbox-side-nav sub-menu">
                    <li class="nav-item" v-for="label in labels" :key="label">
                      <a href="pages/icons/feather-icons.html" class="nav-link">
                        <b-icon-tag class="link-icon" style="fill: #000" />
                        <span class="link-title">{{ label.name }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link collapsed"
                  data-bs-toggle="collapse"
                  href="#users"
                  role="button"
                  aria-expanded="false"
                  aria-controls="users"
                >
                  <i class="link-icon" data-feather="chevron-down"></i>
                  <strong class="link-title">Users</strong>
                </a>
                <div class="collapse" id="users">
                  <ul class="inbox-side-nav sub-menu">
                    <li class="nav-item" v-for="user in users" :key="user">
                      <a href="pages/icons/feather-icons.html" class="nav-link">
                        <b-icon-person-badge
                          class="link-icon"
                          style="fill: #000"
                        />
                        <span class="link-title">{{ user.name }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="newChatModal"
    tabindex="-1"
    aria-labelledby="newChatModalLabel"
    aria-hidden="true"
  >
    <form @submit.prevent="startNewChat">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="varyingModalLabel">Create New Chat</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="mb-3">
                  <label class="form-label">From</label>
                  <select
                    class="form-select"
                    name="selected_channel_id"
                    id="selected_channel_id"
                    v-model="selected_channel_id"
                    required
                  >
                    <option selected="" disabled="">Select channel</option>
                    <option
                      :selected="channel.id == selected_channel_id"
                      :key="channel"
                      :value="channel.id"
                      v-for="channel in channels"
                    >
                      {{ channel.name }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- Col -->
              <div class="col-sm-6">
                <div class="mb-3">
                  <label class="form-label">To</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="(+966)5XXXXXXX"
                    v-model="new_contact_number"
                    no-wheel="true"
                    required
                  />
                </div>
              </div>
              <!-- Col -->
            </div>
            <div class="mb-3">
              <label for="user" class="form-label">Message Type:</label>

              <div class="form-check mb-2">
                <input
                  type="radio"
                  class="form-check-input"
                  name="radioDefault"
                  v-model="message_type"
                  value="default"
                />
                <label class="form-check-label" for="radioDefault">
                  Default Message
                </label>
              </div>
              <div class="form-check mb-2">
                <input
                  type="radio"
                  class="form-check-input"
                  name="radioDefault"
                  v-model="message_type"
                  value="template"
                />
                <label class="form-check-label" for="radioDefault1">
                  WhatsApp Message Template
                </label>
              </div>
            </div>
            <div class="mb-3" v-if="message_type == 'default'">
              <textarea
                v-model="default_message"
                class="form-control"
              ></textarea>
            </div>
            <div v-if="message_type == 'template' && selected_channel_id">
              <div class="row">
                <div class="col-sm-6 mb-3">
                  <label class="form-label">Template</label>
                  <select
                    class="form-select"
                    id="selected_template_id"
                    v-model="selected_template"
                    @change="bodyInputs"
                    required
                  >
                    <option selected="" disabled="">Select template</option>
                    <option
                      :selected="template == selected_template"
                      :key="template"
                      :value="template"
                      v-for="template in templates.filter(
                        (t) => t.channel_id == selected_channel_id
                      )"
                    >
                      {{ template.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="mb-3" v-if="selected_template">
                <label for="message-text" class="form-label"
                  >Template Preview</label
                >
                <div class="row">
                  <div class="col-md-8">
                    <div class="alert alert-secondary" role="alert">
                      <div class="card">
                        <img
                          src="https:placehold.co/300x200/6571fy/FFF?text=y"
                          class="card-img-top"
                          alt="..."
                          v-show="
                            selected_template?.template_header &&
                            selected_template?.header == 'IMAGE'
                          "
                        />

                        <div class="card-body">
                          <h5
                            class="card-title"
                            v-show="
                              selected_template?.template_header &&
                              selected_template?.header == 'TEXT'
                            "
                          >
                            {{ selected_template?.template_header?.value }}
                          </h5>
                          <p class="card-text mb-3">
                            {{
                              selected_template?.body_modified ??
                              selected_template?.body
                            }}
                          </p>
                        </div>
                        <p
                          class="card-text mx-3 mb-3"
                          v-show="selected_template?.footer_text"
                        >
                          <small class="text-muted">{{
                            selected_template?.footer_text
                          }}</small>
                        </p>

                        <div
                          class="card-footer"
                          v-show="selected_template?.template_buttons"
                        >
                          <div
                            class="row mb-1"
                            v-for="button in selected_template?.template_buttons"
                            :key="button"
                          >
                            <div class="col-sm-8">
                              <button
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#newChatModal"
                                data-bs-whatever="@mdo"
                                class="btn btn-primary"
                                :class="{
                                  'btn-icon-text': button.type == 'QUICK_REPLY',
                                }"
                              >
                                {{ button.name }}
                                <BIconReply
                                  v-show="button.type == 'QUICK_REPLY'"
                                ></BIconReply>
                                <BIconTelephone
                                  v-show="button.type == 'PHONE_NUMBER'"
                                ></BIconTelephone>
                                <BIconLink
                                  v-show="button.type == 'URL'"
                                ></BIconLink>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="selected_template?.body_inputs > 0"
                    class="col-md-4"
                  >
                    <div
                      class="row mb-1"
                      v-for="item in body_inputs"
                      :key="item"
                    >
                      <label
                        for="exampleInputUsername2"
                        class="col-sm-2 px-0 col-form-label"
                        >{{ "\{\{" + item.index + "\}\}" }}</label
                      >
                      <div class="col-sm-10 ps-0">
                        <input
                          type="text"
                          class="form-control"
                          v-model="item.value"
                          @input="inputItemChanged(item)"
                          :key="item.index"
                          :id="'exampleInputUsername' + item.index"
                          placeholder="enter the value of label"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary btn-text-icon">
              Send
              <i class="btn-icon-append" data-feather="send"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  BIconHeadphones,
  BIconLink,
  BIconPhone,
  BIconReply,
  BIconTelephone,
} from "bootstrap-icons-vue";

if ($(" -body").length) {
  const sidebarBodyScroll = new PerfectScrollbar("-body");
}

export default {
  data() {
    return {
      users: [],
      teams: [],
      labels: [],
      channels: [],
      inboxes: null,
      assigneds: null,
      loading: false,
      inboxes: [],
      templates: [],
      inbox: null,
      body_inputs: [],
      message_type: "default",
      default_message: null,
      new_contact_number: "249123342124",
      selected_channel_id: null,
      selected_template: null,
      nav_item_selected: "new_inboxes",
    };
  },
  methods: {
    async getInboxIndex() {
      this.loading = true;

      this.http.get("inbox").then((res) => {
        this.loading = false;
        if (res.status) {
          this.inboxes = res.data.inboxes;
          this.assigneds = res.data.assigneds;
          this.users = res.data.users;
          this.teams = res.data.teams;
          this.channels = res.data.channels;
          this.labels = res.data.labels;
          this.templates = res.data.templates;
        }
      });
    },
    bodyInputs() {
      this.body_inputs = [];
      for (var i = 0; i < this.selected_template?.body_inputs; i++) {
        this.body_inputs.push({ index: i + 1, value: null });
      }
    },
    nav_item_select(item) {
      this.nav_item_selected = item;
      this.$emit("inbox_menu_item_tap", item);
    },
    inputItemChanged(item) {
      var str = this.selected_template.body;
      this.selected_template.body_modified = null;
      for (const index in this.body_inputs) {
        if (
          this.body_inputs[index].value != null &&
          this.body_inputs[index].value != ""
        ) {
          this.selected_template.update = true;
          str = str.replace(
            "\{\{" + this.body_inputs[index].index + "\}\}",
            this.body_inputs[index].value
          );
        }
      }

      this.selected_template.body_modified = str;
    },

    startNewChat() {
      console.log("startNewChat");
      console.log(this.new_contact_number);
      console.log(this.selected_channel_id);
      console.log(this.message_type);
      console.log(this.default_message);
      console.log(this.selected_template);
      console.log(this.body_inputs);

      var object_send = {
        phone_number: this.new_contact_number,
        channel_id: this.selected_channel_id,
        message_type: this.message_type,
      };

      if (this.message_type == "default") {
        object_send.message = this.default_message;
      } else if (this.message_type == "template") {
        if (this.selected_template != null) {
          this.selected_template.body_inputs = this.body_inputs;
        }
        if (this.selected_template.body_inputs == 0) {
          object_send.message = this.selected_template.body;
        } else {
          object_send.message = this.selected_template.body_modified;
        }
        object_send.template = this.selected_template;
      }

      this.loading = true;
      this.http.post("inbox/new_chat", object_send).then((res) => {
        console.log(res);
        this.loading = false;

        this.selected_channel_id = null;
        this.message_type = "default";
        this.default_message = null;
        this.selected_template = null;
        this.body_inputs = [];

        $("#newChatModal").modal("toggle");
        $(".modal-backdrop").hide();
        $("#newChatModal").modal("handleUpdate");

        this.$emit("update_inboxes");
        // if (res.status) {
        //   this.getReplays();
        // }
      });
    },
  },
  emits: ["inbox_menu_item_tap", "update_inboxes"],
  mounted() {
    console.log("mounted");
    this.getInboxIndex();
  },
};
</script>

<style scoped>
.chat-wrapper .chat-aside .aside-body .tab-content .tab-pane {
  position: relative;
  max-height: calc(100vh - 275px);
}
.inbox-side-nav {
  display: flex;
  flex-direction: column;
  /* position: relative; */
  list-style: none;
  max-height: calc(100vh - 270px);
  /* overflow: scroll; */
}
.inbox-side-nav .nav-item {
  position: relative;
}
.inbox-side-nav .nav-item .nav-link {
  display: flex;
  align-items: center;
  padding: 0;
  height: 32px;
  white-space: nowrap;
  color: #000;
}
.inbox-side-nav .nav-item .nav-link .link-icon {
  width: 16px;
  height: 16px;
  fill: rgba(233, 236, 239, 0.21);
  position: absolute;
  color: inherit;
}
.inbox-side-nav .nav-item .nav-link .link-title {
  margin-left: 30px;
  font-size: 14px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.inbox-side-nav .nav-item .nav-link .link-arrow {
  width: 14px;
  height: 14px;
  margin-left: auto;
  -webkit-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: inherit;
}
.inbox-side-nav .nav-item .nav-link .link-icon,
.inbox-side-nav .nav-item .nav-link .link-title,
.inbox-side-nav .nav-item .nav-link .link-arrow {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.inbox-side-nav .nav-item .nav-link[aria-expanded="true"] {
  color: #6571ff;
}

.inbox-side-nav .nav-item .nav-link[aria-expanded="true"] .link-arrow {
  -webkit-transform: rotate(90deg);
  transform: rotate(180deg);
}
.inbox-side-nav .nav-item .nav-category {
  color: #7987a1;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
  height: 15px;
}

.inbox-side-nav .nav-item .nav-category:not(:first-child) {
  margin-top: 20px;
}
.inbox-side-nav .nav-item:hover .nav-link {
  color: #6571ff;
}
.inbox-side-nav .nav-item:hover .nav-link .link-title {
  margin-left: 31px;
}
.inbox-side-nav .nav-item:hover .nav-link .link-icon {
  color: #6571ff;
  fill: rgba(239, 243, 255, 0.5);
}
.inbox-side-nav .nav-item.active .nav-link {
  color: #6571ff;
}
.inbox-side-nav .nav-item.active .nav-link::before {
  content: "";
  width: 3px;
  height: 26px;
  background: #6571ff;
  position: absolute;
  left: -25px;
}
.inbox-side-nav .nav-item.active .nav-link .link-icon {
  fill: rgba(239, 243, 255, 0.5);
  color: #6571ff;
}
.inbox-side-nav.sub-menu {
  padding: 0 0 15px 15px;
  list-style: none;
}
.inbox-side-nav.sub-menu .nav-item {
  position: relative;
}
.inbox-side-nav.sub-menu .nav-item .nav-link {
  height: 34px;
  color: #000;
  font-size: 13px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
/*  .nav.sub-menu .nav-item .nav-link::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #aeb9c4;
  position: absolute;
  left: -29px;
  top: 10px;
  -webkit-transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
} */
.inbox-side-nav.sub-menu .nav-item .nav-link.active {
  color: #6571ff;
}

.inbox-side-nav.sub-menu .nav-item .nav-link.active::before {
  border: 1px solid #6571ff;
  background: #6571ff;
}
.inbox-side-nav.sub-menu .nav-item:hover .nav-link {
  color: #6571ff;
  margin-left: 3px;
}

.inbox-side-nav.sub-menu .nav-item .nav-link .link-icon {
  color: inherit;
  fill: rgba(239, 243, 255, 0.5);
}

.inbox-side-nav.sub-menu .nav-item:hover .nav-link .link-icon {
  color: #6571ff;
  fill: rgba(239, 243, 255, 0.5);
}

.inbox-side-nav.sub-menu .nav-item:hover .nav-link::before {
  border: 1px solid #6571ff;
  background: #6571ff;
}
/* @media (max-width: 991px) {
   {
    z-index: 999;
    margin-left: -240px;
    visibility: hidden;
  }
  -open  {
    margin-left: 0;
    visibility: visible;
  }
   .inbox-side-nav.nav-item {
    width: auto;
  }
   .inbox-side-nav.nav-item .nav-link .link-icon {
    -webkit-transition: none;
    transition: none;
    margin: 0;
  }
} */

-dark -header {
  background: #0c1427;
  border-bottom: 1px solid rgba(233, 236, 239, 0.1);
  border-right: 1px solid rgba(233, 236, 239, 0.1);
}
-dark -header -brand {
  color: #e9ecef;
}
-dark -header -toggler span {
  background: #7987a1;
}
-dark -body {
  background: #0c1427;
  border-right: 1px solid rgba(233, 236, 239, 0.1);
}
-dark .nav .nav-item.nav-category {
  color: #fff;
}
-dark .nav .nav-item .nav-link {
  color: #7987a1;
}
-dark .nav .nav-item .nav-link svg {
  fill: none;
}
-dark .nav .nav-item .nav-link[aria-expanded="true"] {
  color: #6571ff;
}
-dark .nav .nav-item:hover .nav-link svg,
-dark .nav .nav-item.active .nav-link svg {
  fill: rgba(101, 113, 255, 0.2);
}
-dark .nav .nav-item:hover .nav-link .link-title,
-dark .nav .nav-item.active .nav-link .link-title {
  color: #6571ff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
