import Swal from "sweetalert2";

export default {
  install(Vue) {
    // var self = Vue.config.globalProperties;
    Vue.config.globalProperties.alerts = {
      requestAlert(
        text = "Are You Sure ?",
        confirmButtonText = "OK",
        cancelButtonText = "Cancel"
      ) {
        return new Promise((resolve, reject) => {
          Swal.fire({
            title: text,
            showCancelButton: true,
            confirmButtonColor: "#249542",
            cancelButtonColor: "gray",
            confirmButtonText,
            cancelButtonText,
          })
            .then(async (result) => {
              if (result.isConfirmed) {
                return resolve(true);
              }
              return resolve(false);
            })
            .catch((error) => {
              reject(false);
              console.error("Error deleting store: ", error);
            });
        });
      },
      responseAlert(
        text = "Operation Done Successfuly",
        title = "Done",
        icon = "success"
      ) {
        Swal.fire({
          title: `${title}`,
          text: ` ${text} `,
          icon: `${icon}`,
          toast: true,
          position: "top-start",
          showConfirmButton: false,
          timer: 2000,
        });
      },
    };
  },
};
