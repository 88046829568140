<template>
  <footer
    class="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small"
  >
    <p class="text-muted mb-1 mb-md-0">
      Copyright © 2022
      <a href="https://www.nobleui.com" target="_blank">NobleUI</a>.
    </p>
    <p class="text-muted">
      Handcrafted With
      <i class="mb-1 text-primary ms-1 icon-sm" data-feather="heart"></i>
    </p>
  </footer>
</template>
