import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SettingsView from "../views/SettingsView.vue";
import Teams from "../components/Settings/Teams.vue";
import Users from "../components/Settings/Users.vue";
import Labels from "../components/Settings/Labels.vue";
import Replays from "../components/Settings/Replays.vue";
import Channels from "../components/Settings/Channels.vue";
import Templates from "../components/Settings/Templates.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/settings/",
    name: "settings",
    component: SettingsView,
    children: [
      {
        path: "",
        component: Teams,
      },
      {
        path: "/settings/teams",
        component: Teams,
      },
      {
        path: "/settings/users",
        component: Users,
      },
      {
        path: "/settings/labels",
        component: Labels,
      },
      {
        path: "/settings/replays",
        component: Replays,
      },
      {
        path: "/settings/channels",
        component: Channels,
      },
      {
        path: "/settings/channels/templates",
        component: Templates,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "history",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
