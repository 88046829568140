<template>
  <div class="col-md-6">
    <div class="">
      <div class="">
        <div class="row">
          <h6 class="card-title">
            <i class="btn-icon-prepend" data-feather="hash"></i> Quick Replays
          </h6>
          <!-- <p class="text-muted mb-3">Add class <code>.table</code></p> -->
        </div>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th>TAG</th>
                <th>MESSAGE</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(replay, index) in replays" :key="replay">
                <td>{{ index + 1 }}</td>
                <td>{{ replay.tag }}</td>
                <td>{{ replay.body }}</td>
                <td>
                  <button type="button" class="btn btn-icon btn-primary">
                    <BIconPencilSquare />
                  </button>
                  ||
                  <button type="button" class="btn btn-danger btn-icon">
                    <BIconTrashFill />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Add New Replay</h6>

        <form class="forms-sample" @submit.prevent="createReplay">
          <div class="mb-3">
            <label for="exampleInputUsername1" class="form-label">Tag</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputUsername1"
              autocomplete="off"
              placeholder="replay tag"
              v-model="replay.tag"
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputUsername2" class="form-label"
              >Message</label
            >
            <input
              type="text"
              class="form-control"
              id="exampleInputUsername2"
              autocomplete="off"
              placeholder="replay message"
              v-model="replay.body"
            />
          </div>

          <button type="submit" class="btn btn-primary me-2">Save</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      replays: [],
      replay: {},
      loading: false,
    };
  },
  methods: {
    alertStyles(color) {
      return {
        "background-color": color + " !important",
        "border-color": color + " !important",
        opacity: 0.5,
      };
    },
    async getReplays() {
      console.log("getReplays");
      this.loading = true;

      this.http.get("settings/replays").then((res) => {
        console.log(res);
        this.loading = false;
        if (res.status) {
          this.replays = res.data;
        }
      });
    },

    async createReplay() {
      console.log(this.replay);

      this.loading = true;
      this.http.post("settings/replays/create", this.replay).then((res) => {
        this.loading = false;
        if (res.status) {
          this.replay = {};
          this.getReplays();
        }
      });
    },
  },
  mounted() {
    console.log("mounted");
    this.getReplays();
  },
};
</script>
