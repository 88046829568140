<template>
  <div class="col-md-10">
    <div class="">
      <div class="">
        <div class="row">
          <div class="col-md-8">
            <h6 class="card-title">
              <i class="btn-icon-prepend" data-feather="tag"></i> WA Business
              Templates
            </h6>
          </div>
          <div class="col-md-4 right">
            <button
              type="button"
              class="btn btn-primary btn-icon"
              data-bs-toggle="modal"
              data-bs-target="#varyingModal"
              data-bs-whatever="@mdo"
            >
              <i class="btn-icon-prepend" data-feather="plus"></i>
            </button>
          </div>
          <!-- <p class="text-muted mb-3">Add class <code>.table</code></p> -->
        </div>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th>NAME</th>
                <th>CHANNEL</th>
                <th>CATEGORY</th>
                <th>LANGUAGE</th>
                <th>---</th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(template, index) in templates" :key="template">
                <td>{{ index + 1 }}</td>
                <td>{{ template.name }}</td>
                <td>{{ template.channel?.name }}</td>
                <td>{{ template.category }}</td>
                <td>{{ template.language }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-primary btn-icon"
                    data-bs-toggle="modal"
                    data-bs-target="#varyingModal"
                    data-bs-whatever="@mdo"
                  >
                    ...
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <form autocomplete="off" @submit.prevent="createTemplate">
    <div
      class="modal fade"
      id="varyingModal"
      tabindex="-1"
      aria-labelledby="varyingModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="varyingModalLabel">
              New WhatsApp Message Template
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="template-name" class="form-label"
                >Template name:</label
              >
              <input
                class="form-control defaultconfig"
                maxlength="50"
                name="name"
                v-model="template.name"
                type="text"
                placeholder="template name"
                :formatter="formatter"
                required
                trim
              />
            </div>
            <div class="mb-3">
              <label for="channel" class="form-label">Channel:</label>
              <p class="text-muted">
                Select the channel vour template will be used on.
              </p>

              <select
                class="form-select"
                v-model="template.channel_id"
                name="chanel"
                id="chanel"
              >
                <option selected="" disabled="">Select your chanel</option>
                <option
                  :selected="channel.id == template.channel_id"
                  :key="channel.id"
                  :value="channel.id"
                  v-for="channel in channels"
                >
                  {{ channel.name }}
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label for="category" class="form-label">Category:</label>
              <select
                class="form-select"
                v-model="template.category"
                name="category"
                id="category"
              >
                <option selected="" disabled="">
                  Select template category
                </option>
                <option value="MARKETING">Marketing</option>
                <option value="UTILITY">Utility</option>
              </select>
            </div>

            <div class="mb-3">
              <label for="language" class="form-label">Language:</label>
              <select
                class="form-select"
                v-model="template.language"
                name="language"
                id="language"
              >
                <option selected="" disabled="">
                  Select template language
                </option>
                <option value="ar">Arabic</option>
                <option value="en_US">English</option>
              </select>
            </div>

            <div class="mb-3">
              <label for="language" class="form-label"
                >Header:
                <div class="form-check form-switch mb-2">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="formSwitch1"
                    v-model="header"
                    value="{{ header }}"
                  />
                  <label class="form-check-label" for="formSwitch1"
                    >By including a header you can add a bold title to the top
                    of your message template</label
                  >
                </div></label
              >
              <div class="row" v-show="header">
                <div class="col-md-4 mb-3">
                  <div class="card" :class="{ 'alert-success': header_text }">
                    <div class="card-body" v-on:click="set_header_text">
                      <h1>TEXT</h1>
                      <p>Title header</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card" :class="{ 'alert-success': header_image }">
                    <div class="card-body" v-on:click="set_header_image">
                      <h1>IMAGE</h1>
                      <p>PNG or JPG</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-show="header_text">
                <div class="col-md-4 mb-3">
                  <input
                    class="form-control defaultconfig"
                    maxlength="60"
                    name="header-text"
                    type="text"
                    v-model="template.header_text"
                    placeholder="header text"
                  />
                </div>
              </div>
              <div class="row" v-show="header_image">
                <div class="col-md-3 mb-3">
                  <input type="file" id="myDropify" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="template_body" class="form-label">Body:</label>
                <p class="text-muted">
                  Enter the text for your message in the language you've
                  selected above.
                </p>

                <textarea
                  class="form-control"
                  v-model="template.body"
                  id="template_body"
                ></textarea>
              </div>
              <div class="col-md-6 mb-3">
                <label for="message-text" class="form-label">_</label>
                <p class="text-muted">preview.</p>
                <div class="alert alert-secondary" role="alert">...</div>
              </div>
            </div>

            <div class="mb-3">
              <label for="language" class="form-label"
                >Footer:
                <div class="form-check form-switch mb-2">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="footer"
                    value="{{ footer }}"
                    id="formSwitch12"
                  />
                  <label class="form-check-label" for="formSwitch1"
                    >Add a short line of text to the bottom of your
                    message</label
                  >
                </div></label
              >
              <div class="row" v-show="footer">
                <div class="col-md-4 mb-3">
                  <input
                    class="form-control defaultconfig"
                    maxlength="60"
                    name="footer-text"
                    v-model="template.footer_text"
                    type="text"
                    placeholder="footer text"
                  />
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label for="language" class="form-label"
                >Buttons:
                <div class="form-check form-switch mb-2">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="has_buttons"
                    value="{{ has_buttons }}"
                    id="formSwitch1"
                  />
                  <label class="form-check-label" for="formSwitch1"
                    >Create up to 3 quick reply buttons that let customers
                    respond to your message.</label
                  >
                </div></label
              >
              <div class="buttons" v-show="has_buttons">
                <div v-for="(button, index) in template.buttons" :key="button">
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <input
                        class="form-control defaultconfig"
                        maxlength="25"
                        name="button[]"
                        v-model="button.value"
                        type="text"
                        placeholder="Button title"
                      />
                    </div>
                    <div class="col-md-2">
                      <button
                        class="btn btn-icon btn-outline-dengar"
                        @click="deleteButton(index)"
                      >
                        X
                      </button>
                    </div>
                  </div>
                </div>

                <button
                  type="button"
                  class="btn btn-icon-text btn-outline-primary"
                  @click="addButton()"
                >
                  <i class="btn-icon-prepend" data-feather="plus"></i>
                  Add New Button
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<style scoped>
.right {
  text-align: right;
}
</style>

<script>
export default {
  data() {
    return {
      templates: [],
      channels: [],
      template: {
        buttons: [],
      },
      header: false,
      header_text: false,
      header_image: false,
      footer: false,
      has_buttons: false,
      loading: false,
    };
  },
  methods: {
    formatter(value) {
      return value.toLowerCase();
    },
    async getTemplates() {
      console.log("getTemplates");
      this.loading = true;

      this.http.get("settings/templates").then((res) => {
        console.log(res);
        this.loading = false;
        if (res.status) {
          this.templates = res.data;
        }
      });
    },
    openModel() {
      this.model = !this.model;
    },
    async getChannels() {
      console.log("getChannels");
      this.loading = true;

      this.http.get("settings/channels").then((res) => {
        console.log(res);
        this.loading = false;
        if (res.status) {
          this.channels = res.data;
        }
      });
    },

    async createTemplate() {
      this.loading = true;

      this.http.post("settings/templates/create", this.template).then((res) => {
        this.loading = false;
        if (res.status) {
          $("#varyingModal").modal("toggle");
          $(".modal-backdrop").hide();
          $("#varyingModal").modal("handleUpdate");
          this.template = {};
          this.getTemplates();
        }
      });
    },

    set_header_text() {
      this.header_text = !this.header_text;
      this.header_image = false;
    },
    set_header_image() {
      this.header_image = !this.header_image;
      this.header_text = false;
    },
    addButton: function () {
      this.template.buttons.push({ value: "" });
    },
    deleteButton: function (index) {
      console.log(index);
      console.log(this.template.buttons);
      this.template.buttons.splice(index, 1);
    },
  },
  mounted() {
    console.log("mounted");
    this.getTemplates();
    this.getChannels();
  },
};
</script>
