import { createApp } from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
// import BootstrapVue3 from "bootstrap-vue-3";
import BootstrapMaxlength from "bootstrap-maxlength";
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";

import store from "./state/store";
import http from "./services/http";
import alerts from "./services/alerts";
import $e from "./services/$e";

createApp(App)
  .use(Vuex)
  .use(store)
  // .use(BootstrapVue3)
  .use(BootstrapMaxlength)
  .use(BootstrapIconsPlugin)
  .use(router)
  .use(http)
  .use(alerts)
  .use($e)
  .mount("#app");
