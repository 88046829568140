<template>
  <div class="row chat-wrapper">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row position-relative">
            <SettingsSide />
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsSide from "./Settings/SettingsSide";
import Teams from "./Settings/Teams";

export default {
  name: "SettingsView",
  components: {
    SettingsSide,
    Teams,
  },
};
</script>

<style scoped>
.card {
  height: 100%;
}
</style>
