<template>
  <nav class="sidebar">
    <div class="sidebar-header">
      <a href="#" class="sidebar-brand"> Noble<span>UI</span> </a>
      <div class="sidebar-toggler not-active">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="sidebar-body">
      <ul class="nav">
        <li class="nav-item nav-category">Main</li>
        <li class="nav-item">
          <a href="/" class="nav-link">
            <i class="link-icon" data-feather="inbox"></i>
            <span class="link-title">Inbox</span>
          </a>
        </li>
        <li class="nav-item nav-category">web apps</li>

        <li class="nav-item">
          <a href="#" class="nav-link">
            <i class="link-icon" data-feather="shield"></i>
            <span class="link-title">Community</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link">
            <i class="link-icon" data-feather="users"></i>
            <span class="link-title">Contacts</span>
          </a>
        </li>

        <li class="nav-item nav-category">Admin</li>
        <li class="nav-item">
          <a href="/settings" class="nav-link">
            <i class="link-icon" data-feather="settings"></i>
            <span class="link-title">Settings</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>
