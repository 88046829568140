<template>
  <div class="col-lg-3 col-md-8 chat-aside border-end-lg">
    <div class="aside-content">
      <div class="aside-header">
        <form class="search-form">
          <div class="input-group">
            <span class="input-group-text">
              <i data-feather="search" class="cursor-pointer"></i>
            </span>
            <input
              type="text"
              class="form-control"
              id="searchForm"
              placeholder="Search here..."
            />
          </div>
        </form>
      </div>
      <div class="aside-body">
        <ul
          v-show="has_fillter_taps"
          class="nav nav-tabs nav-fill mt-3"
          role="tablist"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              id="chats-tab"
              data-bs-toggle="tab"
              data-bs-target="#chats"
              role="tab"
              aria-controls="chats"
              aria-selected="true"
              v-on:click="filtter_by_new"
            >
              <div
                class="d-flex flex-row flex-lg-column flex-xl-row align-items-center justify-content-center"
              >
                <i
                  data-feather="message-square"
                  class="icon-sm me-sm-2 me-lg-0 me-xl-2 mb-md-1 mb-xl-0"
                ></i>
                <p class="d-none d-sm-block">لم يتم الرد</p>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="chats-tab"
              data-bs-toggle="tab"
              data-bs-target="#chats"
              role="tab"
              aria-controls="chats"
              aria-selected="false"
              v-on:click="filtter_by_all"
            >
              <div
                class="d-flex flex-row flex-lg-column flex-xl-row align-items-center justify-content-center"
              >
                <i
                  data-feather="list"
                  class="icon-sm me-sm-2 me-lg-0 me-xl-2 mb-md-1 mb-xl-0"
                ></i>
                <p class="d-none d-sm-block">الجميع</p>
              </div>
            </a>
          </li>
        </ul>
        <div class="tab-content mt-3">
          <div
            class="tab-pane fade show active"
            id="chats"
            role="tabpanel"
            aria-labelledby="chats-tab"
          >
            <div>
              <p class="text-body mb-1">{{ tag_name }}</p>
              <ul
                class="list-unstyled chat-list px-1"
                v-show="inboxes_filltred.length > 0"
              >
                <li
                  class="chat-item pe-1"
                  v-for="inbox in inboxes_filltred"
                  :key="inbox"
                  v-on:click="this.$emit('selected_inbox', inbox)"
                >
                  <a href="#" class="d-flex align-items-center">
                    <figure class="mb-0 me-2">
                      <!-- <img
                        src="https://via.placeholder.com/37x37"
                        class="img-xs rounded-circle"
                        alt="user"
                      /> -->
                      <img
                        v-if="inbox?.contact.name"
                        :src="
                          'https:placehold.co/34x34/6571f' +
                          inbox?.contact.name[0] +
                          '/FFF?text=' +
                          inbox?.contact.name[0]
                        "
                        class="img-xs rounded-circle"
                        alt="avatar"
                      />

                      <img
                        v-if="!inbox?.contact.name"
                        :src="'https:placehold.co/34x34/6571fu/FFF?text=UnNamed'"
                        class="img-xs rounded-circle"
                        alt="avatar"
                      />
                    </figure>
                    <div
                      class="d-flex justify-content-between flex-grow-1 border-bottom"
                    >
                      <div>
                        <p class="text-body fw-bolder">
                          {{ inbox.contact.name ?? inbox.contact.phone }}
                        </p>

                        <p
                          v-if="inbox.messages[0].type == 'text'"
                          class="text-muted tx-13"
                        >
                          {{ inbox.messages[0].message }}
                        </p>
                        <div
                          v-if="inbox.messages[0].type == 'template'"
                          class="d-flex align-items-center"
                        >
                          <b-icon-reply
                            class="text-muted icon-md mb-2px"
                          ></b-icon-reply>
                          <p class="text-muted ms-1">
                            {{
                              inbox.messages[0].messages_template.template.name
                            }}
                          </p>
                        </div>
                        <div
                          v-if="inbox.messages[0].type == 'image'"
                          class="d-flex align-items-center"
                        >
                          <b-icon-image
                            class="text-muted icon-md mb-2px"
                          ></b-icon-image>
                          <p class="text-muted ms-1">Photo</p>
                        </div>
                        <span
                          v-for="inbox_label in inbox.inbox_labels"
                          :key="inbox_label"
                          class="badge border me-2"
                          :style="tagStyles(inbox_label.label.color)"
                          >{{ inbox_label.label.name }}</span
                        >
                      </div>
                      <div class="d-flex flex-column align-items-end">
                        <p class="text-muted tx-13 mb-1">
                          <Timeago :datetime="inbox.contact.created" />
                        </p>
                        <div class="badge rounded-pill bg-primary ms-auto">
                          {{ inbox.un_viewed_messages }}
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
              <div
                class="container text-center"
                v-show="inboxes_filltred.length == 0"
              >
                <img
                  src="/assets/images/send-message.png"
                  width="25%"
                  alt=""
                  class="mb-1 mt-5"
                  srcset=""
                />
                <p>لم يتم العثور على محادثات</p>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="calls"
            role="tabpanel"
            aria-labelledby="calls-tab"
          >
            <p class="text-muted mb-1">Recent calls</p>
            <ul class="list-unstyled chat-list px-1">
              <li class="chat-item pe-1">
                <a href="javascript:;" class="d-flex align-items-center">
                  <figure class="mb-0 me-2">
                    <img
                      src="https://via.placeholder.com/37x37"
                      class="img-xs rounded-circle"
                      alt="user"
                    />
                    <div class="status online"></div>
                  </figure>
                  <div
                    class="d-flex align-items-center justify-content-between flex-grow-1 border-bottom"
                  >
                    <div>
                      <p class="text-body">Jensen Combs</p>
                      <div class="d-flex align-items-center">
                        <i
                          data-feather="arrow-up-right"
                          class="icon-sm text-success me-1"
                        ></i>
                        <p class="text-muted tx-13">Today, 03:11 AM</p>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-end">
                      <i
                        data-feather="phone-call"
                        class="text-primary icon-md"
                      ></i>
                    </div>
                  </div>
                </a>
              </li>
              <li class="chat-item pe-1">
                <a href="javascript:;" class="d-flex align-items-center">
                  <figure class="mb-0 me-2">
                    <img
                      src="https://via.placeholder.com/37x37"
                      class="img-xs rounded-circle"
                      alt="user"
                    />
                    <div class="status offline"></div>
                  </figure>
                  <div
                    class="d-flex align-items-center justify-content-between flex-grow-1 border-bottom"
                  >
                    <div>
                      <p class="text-body">Leonardo Payne</p>
                      <div class="d-flex align-items-center">
                        <i
                          data-feather="arrow-down-left"
                          class="icon-sm text-success me-1"
                        ></i>
                        <p class="text-muted tx-13">Today, 11:41 AM</p>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-end">
                      <i data-feather="video" class="text-primary icon-md"></i>
                    </div>
                  </div>
                </a>
              </li>
              <li class="chat-item pe-1">
                <a href="javascript:;" class="d-flex align-items-center">
                  <figure class="mb-0 me-2">
                    <img
                      src="https://via.placeholder.com/37x37"
                      class="img-xs rounded-circle"
                      alt="user"
                    />
                    <div class="status offline"></div>
                  </figure>
                  <div
                    class="d-flex align-items-center justify-content-between flex-grow-1 border-bottom"
                  >
                    <div>
                      <p class="text-body">Carl Henson</p>
                      <div class="d-flex align-items-center">
                        <i
                          data-feather="arrow-down-left"
                          class="icon-sm text-danger me-1"
                        ></i>
                        <p class="text-muted tx-13">Today, 04:24 PM</p>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-end">
                      <i
                        data-feather="phone-call"
                        class="text-primary icon-md"
                      ></i>
                    </div>
                  </div>
                </a>
              </li>
              <li class="chat-item pe-1">
                <a href="javascript:;" class="d-flex align-items-center">
                  <figure class="mb-0 me-2">
                    <img
                      src="https://via.placeholder.com/37x37"
                      class="img-xs rounded-circle"
                      alt="user"
                    />
                    <div class="status online"></div>
                  </figure>
                  <div
                    class="d-flex align-items-center justify-content-between flex-grow-1 border-bottom"
                  >
                    <div>
                      <p class="text-body">Jensen Combs</p>
                      <div class="d-flex align-items-center">
                        <i
                          data-feather="arrow-down-left"
                          class="icon-sm text-danger me-1"
                        ></i>
                        <p class="text-muted tx-13">Today, 12:53 AM</p>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-end">
                      <i data-feather="video" class="text-primary icon-md"></i>
                    </div>
                  </div>
                </a>
              </li>
              <li class="chat-item pe-1">
                <a href="javascript:;" class="d-flex align-items-center">
                  <figure class="mb-0 me-2">
                    <img
                      src="https://via.placeholder.com/37x37"
                      class="img-xs rounded-circle"
                      alt="user"
                    />
                    <div class="status online"></div>
                  </figure>
                  <div
                    class="d-flex align-items-center justify-content-between flex-grow-1 border-bottom"
                  >
                    <div>
                      <p class="text-body">John Doe</p>
                      <div class="d-flex align-items-center">
                        <i
                          data-feather="arrow-down-left"
                          class="icon-sm text-success me-1"
                        ></i>
                        <p class="text-muted tx-13">Today, 01:42 AM</p>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-end">
                      <i data-feather="video" class="text-primary icon-md"></i>
                    </div>
                  </div>
                </a>
              </li>
              <li class="chat-item pe-1">
                <a href="javascript:;" class="d-flex align-items-center">
                  <figure class="mb-0 me-2">
                    <img
                      src="https://via.placeholder.com/37x37"
                      class="img-xs rounded-circle"
                      alt="user"
                    />
                    <div class="status offline"></div>
                  </figure>
                  <div
                    class="d-flex align-items-center justify-content-between flex-grow-1 border-bottom"
                  >
                    <div>
                      <p class="text-body">John Doe</p>
                      <div class="d-flex align-items-center">
                        <i
                          data-feather="arrow-up-right"
                          class="icon-sm text-success me-1"
                        ></i>
                        <p class="text-muted tx-13">Today, 12:01 AM</p>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-end">
                      <i
                        data-feather="phone-call"
                        class="text-primary icon-md"
                      ></i>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Timeago } from "vue2-timeago";

export default {
  components: {
    Timeago,
  },
  props: { inboxes_type: String, tag_name: String, has_fillter_taps: Boolean },
  data() {
    return {
      loading: false,
      inboxes: [],
      inboxes_filltred: [],
      inbox: null,
    };
  },
  methods: {
    async getInboxes(route) {
      console.log("getInboxes: " + route);
      this.loading = true;
      this.http.get("inbox/" + route).then((res) => {
        console.log(res);
        this.loading = false;
        if (res.status) {
          this.inboxes = res.data;
          if (this.has_fillter_taps) {
            this.filtter_by_new();
          } else {
            this.filtter_by_all();
          }
          if (this.inboxes.length > 0) {
            console.log(this.inboxes[0]);
            // this.$emit("selected_inbox", this.inboxes[0]);
          }
        }
      });
    },

    filtter_by_new() {
      this.inboxes_filltred = this.inboxes.filter(
        (e) => e.un_viewed_messages > 0
      );
    },
    filtter_by_all() {
      console.log("all colled");
      this.inboxes_filltred = this.inboxes;
    },
    loadInboxes() {
      this.getInboxes(this.inboxes_type);
    },
    tagStyles(color) {
      return {
        "background-color": color + "2b" + " !important",
        "border-color": color + " !important",
        color: color,
      };
    },
  },
  mounted() {
    console.log("mounted");
    this.loadInboxes();
  },
};
</script>

<style scoped>
.chat-wrapper .chat-aside .aside-body .tab-content .tab-pane {
  position: relative;
  max-height: calc(100vh - 322px);
}
.center-text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
</style>
