<template>
  <div class="col-md-6">
    <div class="">
      <div class="">
        <div class="row">
          <div class="col-md-8">
            <h6 class="card-title">
              <i class="btn-icon-prepend" data-feather="tag"></i> WA Business
              Channel
            </h6>
          </div>
          <div class="col-md-4 right">
            <a href="/settings/channels/templates"> Manage Templates </a>
          </div>
          <!-- <p class="text-muted mb-3">Add class <code>.table</code></p> -->
        </div>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th>NAME</th>
                <th>PHONE ID</th>
                <th>PHONE NUMBER</th>
                <th>ACCOUNT ID</th>
              </tr>
            </thead>

            <tbody v-if="!loading">
              <tr v-for="(channel, index) in channels" :key="channel">
                <td>{{ index + 1 }}</td>
                <td>{{ channel.name }}</td>
                <td>{{ channel.phone_id }}</td>
                <td>{{ channel.phone_number }}</td>
                <td>{{ channel.account_id }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Add New Channel</h6>

        <form class="forms-sample" @submit.prevent="createChannel">
          <div class="mb-3">
            <label for="exampleInputUsername1" class="form-label">Name</label>
            <input
              type="text"
              v-model="channel.name"
              name="name"
              class="form-control"
              id="exampleInputUsername1"
              autocomplete="off"
              placeholder="channel name"
            />
          </div>
          <div class="mb-3">
            <label for="phone_id" class="form-label">Phone ID</label>
            <input
              type="number"
              class="form-control"
              v-model="channel.phone_id"
              id="phone_id"
              autocomplete="off"
              placeholder="phone id"
              no-wheel="true"
            />
          </div>

          <div class="mb-3">
            <label for="phone_number" class="form-label">Phone Number</label>
            <input
              type="number"
              class="form-control"
              v-model="channel.phone_number"
              id="phone_number"
              autocomplete="off"
              placeholder="phone number"
              no-wheel
            />
          </div>

          <div class="mb-3">
            <label for="account_id" class="form-label"
              >BUSINESS ACCOUNT ID</label
            >
            <input
              type="number"
              class="form-control"
              v-model="channel.account_id"
              id="account_id"
              autocomplete="off"
              placeholder="account id"
              no-wheel
            />
          </div>
          <div class="mb-3">
            <label for="token" class="form-label">Token</label>
            <input
              type="text"
              class="form-control"
              id="token"
              v-model="channel.token"
              autocomplete="off"
              placeholder="account token"
            />
          </div>

          <button type="submit" class="btn btn-primary me-2">Save</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //   setup() {
  //     return {};
  //   },
  data() {
    return {
      channels: [],
      channel: {},
      loading: false,
    };
  },
  methods: {
    async getChannels() {
      console.log("getChannels");
      this.loading = true;

      this.http.get("settings/channels").then((res) => {
        console.log(res);
        this.loading = false;
        if (res.status) {
          this.channels = res.data;
        }
      });
    },

    async createChannel() {
      this.loading = true;
      console.log(this.channel);
      this.http.post("settings/channels/create", this.channel).then((res) => {
        this.loading = false;
        if (res.status) {
          this.channel = {};
          this.getChannels();
        }
      });
    },
  },
  mounted() {
    console.log("mounted");
    this.getChannels();
  },
};
</script>

<style scoped>
.right {
  text-align: right;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
