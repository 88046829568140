<template>
  <div class="col-lg-2 col-md-4 chat-aside border-end-lg">
    <div class="aside-content">
      <div class="aside-body">
        <div class="tab-content mt-3">
          <div
            class="tab-pane fade show active"
            id="side"
            role="tabpanel"
            aria-labelledby="chats-tab"
          >
            <ul class="list-unstyled inbox-side-nav chat-list px-1">
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="collapse"
                  href="#inbox"
                  role="button"
                  aria-expanded="true"
                  aria-controls="inbox"
                >
                  <i class="link-icon" data-feather="chevron-down"></i>
                  <strong class="link-title">Organization</strong>
                </a>
                <div class="collapse show" id="inbox">
                  <ul class="inbox-side-nav sub-menu">
                    <li class="nav-item">
                      <a href="/settings/teams" class="nav-link">
                        <i class="link-icon" data-feather="hash"></i>
                        <span class="link-title">Teams</span>
                        <span class="link-arrow"> {{ teams }} </span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="/settings/users" class="nav-link">
                        <i class="link-icon" data-feather="user"></i>
                        <span class="link-title">users</span>
                        <span class="link-arrow"> {{ users }} </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link collapsed"
                  data-bs-toggle="collapse"
                  href="#personal"
                  role="button"
                  aria-expanded="true"
                  aria-controls="personal"
                >
                  <i class="link-icon" data-feather="chevron-down"></i>
                  <strong class="link-title">Chanels</strong>
                </a>
                <div class="collapse show" id="personal">
                  <ul class="inbox-side-nav sub-menu">
                    <li class="nav-item">
                      <a href="/settings/channels" class="nav-link">
                        <i class="link-icon" data-feather="at-sign"></i>
                        <span class="link-title">Whatsapp</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="collapse"
                  href="#team-work"
                  role="button"
                  aria-expanded="false"
                  aria-controls="team-work"
                >
                  <i class="link-icon" data-feather="chevron-down"></i>
                  <strong class="link-title">Settings</strong>
                </a>
                <div class="collapse show" id="team-work">
                  <ul class="inbox-side-nav sub-menu">
                    <li class="nav-item">
                      <a href="/settings/labels" class="nav-link">
                        <i class="link-icon" data-feather="tag"></i>
                        <span class="link-title">Labels</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a href="/settings/replays" class="nav-link">
                        <i class="link-icon" data-feather="info"></i>
                        <span class="link-title">Quick Replays</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
if ($(" -body").length) {
  const sidebarBodyScroll = new PerfectScrollbar("-body");
}

export default {
  data() {
    return {
      users: null,
      teams: null,
      loading: false,
    };
  },
  methods: {
    async getSettings() {
      console.log("getSettings");
      this.loading = true;

      this.http.get("settings").then((res) => {
        console.log(res);
        this.loading = false;
        if (res.status) {
          this.users = res.data.users;
          this.teams = res.data.teams;
        }
      });
    },
  },
  mounted() {
    console.log("mounted");
    this.getSettings();
  },
};
</script>

<style scoped>
.chat-wrapper .chat-aside .aside-body .tab-content .tab-pane {
  position: relative;
  max-height: calc(100vh - 275px);
}
.inbox-side-nav {
  display: flex;
  flex-direction: column;
  /* position: relative; */
  list-style: none;
  max-height: calc(100vh - 270px);
  /* overflow: scroll; */
}
.inbox-side-nav .nav-item {
  position: relative;
}
.inbox-side-nav .nav-item .nav-link {
  display: flex;
  align-items: center;
  padding: 0;
  height: 32px;
  white-space: nowrap;
  color: #000;
}
.inbox-side-nav .nav-item .nav-link .link-icon {
  width: 16px;
  height: 16px;
  fill: rgba(233, 236, 239, 0.21);
  position: absolute;
  color: inherit;
}
.inbox-side-nav .nav-item .nav-link .link-title {
  margin-left: 30px;
  font-size: 14px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.inbox-side-nav .nav-item .nav-link .link-arrow {
  width: 14px;
  height: 14px;
  margin-left: auto;
  -webkit-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: inherit;
}
.inbox-side-nav .nav-item .nav-link .link-icon,
.inbox-side-nav .nav-item .nav-link .link-title,
.inbox-side-nav .nav-item .nav-link .link-arrow {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.inbox-side-nav .nav-item .nav-link[aria-expanded="true"] {
  color: #6571ff;
}

.inbox-side-nav .nav-item .nav-link[aria-expanded="true"] .link-arrow {
  -webkit-transform: rotate(90deg);
  transform: rotate(180deg);
}
.inbox-side-nav .nav-item .nav-category {
  color: #7987a1;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
  height: 15px;
}

.inbox-side-nav .nav-item .nav-category:not(:first-child) {
  margin-top: 20px;
}
.inbox-side-nav .nav-item:hover .nav-link {
  color: #6571ff;
}
.inbox-side-nav .nav-item:hover .nav-link .link-title {
  margin-left: 31px;
}
.inbox-side-nav .nav-item:hover .nav-link .link-icon {
  color: #6571ff;
  fill: rgba(239, 243, 255, 0.5);
}
.inbox-side-nav .nav-item.active .nav-link {
  color: #6571ff;
}
.inbox-side-nav .nav-item.active .nav-link::before {
  content: "";
  width: 3px;
  height: 26px;
  background: #6571ff;
  position: absolute;
  left: -25px;
}
.inbox-side-nav .nav-item.active .nav-link .link-icon {
  fill: rgba(239, 243, 255, 0.5);
  color: #6571ff;
}
.inbox-side-nav.sub-menu {
  padding: 0 0 15px 15px;
  list-style: none;
}
.inbox-side-nav.sub-menu .nav-item {
  position: relative;
}
.inbox-side-nav.sub-menu .nav-item .nav-link {
  height: 34px;
  color: #000;
  font-size: 13px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
/*  .nav.sub-menu .nav-item .nav-link::before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: transparent;
      border: 1px solid #aeb9c4;
      position: absolute;
      left: -29px;
      top: 10px;
      -webkit-transition: all 0.7s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    } */
.inbox-side-nav.sub-menu .nav-item .nav-link.active {
  color: #6571ff;
}

.inbox-side-nav.sub-menu .nav-item .nav-link.active::before {
  border: 1px solid #6571ff;
  background: #6571ff;
}
.inbox-side-nav.sub-menu .nav-item:hover .nav-link {
  color: #6571ff;
  margin-left: 3px;
}

.inbox-side-nav.sub-menu .nav-item .nav-link .link-icon {
  color: inherit;
  fill: rgba(239, 243, 255, 0.5);
}

.inbox-side-nav.sub-menu .nav-item:hover .nav-link .link-icon {
  color: #6571ff;
  fill: rgba(239, 243, 255, 0.5);
}

.inbox-side-nav.sub-menu .nav-item:hover .nav-link::before {
  border: 1px solid #6571ff;
  background: #6571ff;
}
/* @media (max-width: 991px) {
       {
        z-index: 999;
        margin-left: -240px;
        visibility: hidden;
      }
      -open  {
        margin-left: 0;
        visibility: visible;
      }
       .inbox-side-nav.nav-item {
        width: auto;
      }
       .inbox-side-nav.nav-item .nav-link .link-icon {
        -webkit-transition: none;
        transition: none;
        margin: 0;
      }
    } */

-dark -header {
  background: #0c1427;
  border-bottom: 1px solid rgba(233, 236, 239, 0.1);
  border-right: 1px solid rgba(233, 236, 239, 0.1);
}
-dark -header -brand {
  color: #e9ecef;
}
-dark -header -toggler span {
  background: #7987a1;
}
-dark -body {
  background: #0c1427;
  border-right: 1px solid rgba(233, 236, 239, 0.1);
}
-dark .nav .nav-item.nav-category {
  color: #fff;
}
-dark .nav .nav-item .nav-link {
  color: #7987a1;
}
-dark .nav .nav-item .nav-link svg {
  fill: none;
}
-dark .nav .nav-item .nav-link[aria-expanded="true"] {
  color: #6571ff;
}
-dark .nav .nav-item:hover .nav-link svg,
-dark .nav .nav-item.active .nav-link svg {
  fill: rgba(101, 113, 255, 0.2);
}
-dark .nav .nav-item:hover .nav-link .link-title,
-dark .nav .nav-item.active .nav-link .link-title {
  color: #6571ff;
}
</style>
