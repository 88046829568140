<template>
  <div class="col-md-6">
    <div class="">
      <div class="">
        <div class="row">
          <div class="col-md-10">
            <h6 class="card-title">
              <i class="btn-icon-prepend" data-feather="users"></i> Users
            </h6>
            <!-- <p class="text-muted mb-3">Add class <code>.table</code></p> -->
          </div>
          <!-- 
          <div class="col-md-2">
            <button
              type="button"
              class="btn btn-primary btn-icon-text"
              data-bs-toggle="modal"
              data-bs-target="#varyingModal"
              data-bs-whatever="@mdo"
            >
              <i class="btn-icon-prepend" data-feather="edit"></i>
              إضافة
            </button>
          </div> -->
        </div>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th>NAME</th>
                <th>PHONE</th>
                <th>EMAIL</th>
                <th>ACTIONS</th>
              </tr>
            </thead>

            <tbody v-if="!loading">
              <tr v-for="(user, index) in users" :key="user">
                <td>{{ index + 1 }}</td>
                <td>{{ user.name }}</td>
                <td>{{ user.phone }}</td>
                <td>{{ user.email }}</td>
                <td>
                  <button type="button" class="btn btn-icon btn-primary">
                    <BIconPencilSquare />
                  </button>
                  ||
                  <button type="button" class="btn btn-danger btn-icon">
                    <BIconTrashFill />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Add New User</h6>

        <form class="forms-sample" @submit.prevent="createUser">
          <div class="mb-3">
            <label for="exampleInputUsername1" class="form-label">Name</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputUsername1"
              autocomplete="off"
              placeholder="user name"
              v-model="user.name"
            />
          </div>

          <div class="mb-3">
            <label for="exampleInputUsername1" class="form-label">Phone</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputUsername1"
              autocomplete="off"
              placeholder="phone number"
              v-model="user.phone"
            />
          </div>

          <div class="mb-3">
            <label for="exampleInputUsername1" class="form-label">Email</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputUsername1"
              autocomplete="off"
              placeholder="email address"
              v-model="user.email"
            />
          </div>
          <div class="mb-3">
            <label for="user_type_id" class="form-label">User Type:</label>

            <select
              class="form-select"
              v-model="user.user_type_id"
              name="user_type_id"
              id="user_type_id"
            >
              <option selected="" disabled="">Select user type</option>
              <option
                :selected="userType.id == user.user_type_id"
                :key="userType.id"
                :value="userType.id"
                v-for="userType in user_types"
              >
                {{ userType.name }}
              </option>
            </select>
          </div>

          <button type="submit" class="btn btn-primary me-2">Save</button>
        </form>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="varyingModal"
    tabindex="-1"
    aria-labelledby="varyingModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="varyingModalLabel">New User</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="btn-close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <label for="recipient-name" class="form-label">Name:</label>
              <input type="text" class="form-control" id="recipient-name" />
            </div>
            <div class="mb-3">
              <label for="recipient-name" class="form-label">Phone:</label>
              <input type="text" class="form-control" id="recipient-name" />
            </div>
            <div class="mb-3">
              <label for="recipient-name" class="form-label">Email:</label>
              <input type="text" class="form-control" id="recipient-name" />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// var varyingModal = document.getElementById("varyingModal");
// if (varyingModal) {
//   varyingModal.addEventListener("show.bs.modal", function (event) {
//     // Button that triggered the modal
//     var button = event.relatedTarget;
//     // Extract info from data-bs-* attributes
//     var recipient = button.getAttribute("data-bs-whatever");
//     // If necessary, you could initiate an AJAX request here
//     // and then do the updating in a callback.
//     //
//     // Update the modal's content.
//     var modalTitle = varyingModal.querySelector(".modal-title");
//     var modalBodyInput = varyingModal.querySelector(".modal-body input");

//     modalTitle.textContent = "New message to " + recipient;
//     modalBodyInput.value = recipient;
//   });
// }

export default {
  data() {
    return {
      user_types: [],
      users: [],
      user: {},
      loading: false,
    };
  },
  methods: {
    alertStyles(color) {
      return {
        "background-color": color + " !important",
        "border-color": color + " !important",
        opacity: 0.5,
      };
    },
    async getUsersTypes() {
      console.log("getUsersTypes");
      this.http.get("settings/users_types").then((res) => {
        console.log(res);
        if (res.status) {
          this.user_types = res.data;
        }
      });
    },
    async getUsers() {
      console.log("getUsers");
      this.loading = true;

      this.http.get("settings/users").then((res) => {
        console.log(res);
        this.loading = false;
        if (res.status) {
          this.users = res.data;
        }
      });
    },
    async createUser() {
      console.log(this.user);

      this.loading = true;
      this.http.post("settings/users/create", this.user).then((res) => {
        this.loading = false;
        if (res.status) {
          this.user = {};
          this.getUsers();
        }
      });
    },
  },
  mounted() {
    console.log("mounted");
    this.getUsers();
    this.getUsersTypes();
  },
};
</script>
