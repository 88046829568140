<template>
  <div class="col-md-6">
    <div class="">
      <div class="">
        <div class="row">
          <div class="col-md-10">
            <h6 class="card-title">
              <i class="btn-icon-prepend" data-feather="hash"></i> Teams
            </h6>
            <!-- <p class="text-muted mb-3">Add class <code>.table</code></p> -->
          </div>

          <div class="col-md-2">
            <!-- <button
              type="button"
              class="btn btn-primary btn-icon-text"
              data-bs-toggle="modal"
              data-bs-target="#varyingModal"
              data-bs-whatever="@mdo"
            >
              <i class="btn-icon-prepend" data-feather="edit"></i>
              إضافة
            </button> -->
          </div>
        </div>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th>NAME</th>
                <th>USERS</th>
                <th>ACTIONS</th>
                <th>ADD USER</th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(team, index) in teams" :key="team">
                <td>{{ index + 1 }}</td>
                <td>{{ team.name }}</td>
                <td>{{ team.team_users.length }}</td>
                <td>
                  <button type="button" class="btn btn-icon btn-primary">
                    <BIconPencilSquare />
                  </button>
                  ||
                  <button type="button" class="btn btn-danger btn-icon">
                    <BIconTrashFill />
                  </button>
                </td>

                <td>
                  <button
                    @click="assiginUserToTeam(team)"
                    type="button"
                    class="btn btn-primary btn-text"
                  >
                    Add user
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Add New Team</h6>

        <form class="forms-sample" @submit.prevent="createTeam">
          <div class="mb-3">
            <label for="exampleInputUsername1" class="form-label">Name</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputUsername1"
              autocomplete="off"
              placeholder="team name"
              v-model="team.name"
            />
          </div>

          <button type="submit" class="btn btn-primary me-2">Save</button>
        </form>
      </div>
    </div>
  </div>

  <form @submit.prevent="assiginUser">
    <div
      class="modal fade"
      id="varyingModal"
      tabindex="-1"
      aria-labelledby="varyingModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="varyingModalLabel">
              Add user to {{ selected_team?.id }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="user" class="form-label">User Type:</label>

              <select
                class="form-select"
                v-model="selected_user_id"
                name="user"
                id="user"
              >
                <option selected="" disabled="">Select user type</option>
                <option
                  :selected="user.id == selected_user_id"
                  :key="user.id"
                  :value="user.id"
                  v-for="user in users"
                >
                  {{ user.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      teams: [],
      users: [],
      team: {},
      selected_team: null,
      selected_user_id: null,
      loading: false,
    };
  },
  methods: {
    alertStyles(color) {
      return {
        "background-color": color + " !important",
        "border-color": color + " !important",
        opacity: 0.5,
      };
    },
    async getTeams() {
      console.log("getTeams");
      this.loading = true;

      this.http.get("settings/teams").then((res) => {
        console.log(res);
        this.loading = false;
        if (res.status) {
          this.teams = res.data;
        }
      });
    },
    assiginUserToTeam(team) {
      $("#varyingModal").modal("toggle");
      $(".modal-backdrop").show();
      $("#varyingModal").modal("handleUpdate");
      this.selected_team = team;
    },
    async assiginUser() {
      if (this.selected_team == null || this.selected_user_id == null) {
        return;
      }

      this.loading = true;
      console.log({
        team_id: this.selected_team.id,
        user_id: this.selected_user_id,
      });
      this.http
        .post("settings/teams/assgin_user", {
          team_id: this.selected_team.id,
          user_id: this.selected_user_id,
        })
        .then((res) => {
          this.loading = false;
          if (res.status) {
            $("#varyingModal").modal("toggle");
            $(".modal-backdrop").hide();
            $("#varyingModal").modal("handleUpdate");

            this.selected_team = null;
            this.selected_user_id = null;
            this.getTeams();
          }
        });
    },
    async createTeam() {
      console.log(this.team);

      this.loading = true;
      this.http.post("settings/teams/create", this.team).then((res) => {
        this.loading = false;
        if (res.status) {
          this.team = {};
          this.getTeams();
        }
      });
    },
    async getUsers() {
      console.log("getUsers");
      this.http.get("settings/users").then((res) => {
        console.log(res);
        if (res.status) {
          this.users = res.data;
        }
      });
    },
  },
  mounted() {
    console.log("mounted");
    this.getTeams();
    this.getUsers();
  },
};
</script>
