<template>
  <div class="col-lg-7 chat-content">
    <div class="chat-header border-bottom pb-2">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <i
            data-feather="corner-up-left"
            id="backToChatList"
            class="icon-lg me-2 ms-n2 text-muted d-lg-none"
          ></i>
          <figure class="mb-0 me-2">
            <img
              v-if="inbox?.contact.name"
              :src="
                'https:placehold.co/34x34/6571f' +
                inbox?.contact.name[0] +
                '/FFF?text=' +
                inbox?.contact.name[0]
              "
              class="img-xs rounded-circle"
              alt="avatar"
            />

            <img
              v-if="!inbox?.contact.name"
              :src="'https:placehold.co/34x34/6571fu/FFF?text=UnNamed'"
              class="img-xs rounded-circle"
              alt="avatar"
            />
          </figure>
          <div>
            <p>{{ inbox?.contact.name ?? inbox?.contact.phone }}</p>
            <span
              v-for="inbox_label in inbox?.inbox_labels"
              :key="inbox_label"
              class="badge border me-2"
              :style="tagStyles(inbox_label.label.color)"
              >{{ inbox_label.label.name }}</span
            >
          </div>
        </div>
        <div class="d-flex align-items-center me-n1">
          <a class="me-0 me-sm-3" type="button" href="#" role="button">
            <BIconStar
              class="h-2m"
              data-bs-toggle="tooltip"
              data-bs-title="أضف للمفضلة"
            />
          </a>
          <a
            class="me-0 me-sm-3"
            data-bs-title="تصنيف المحادثة"
            type="button"
            href="#"
            id="DropdownTags"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i
              data-feather="tag"
              data-bs-toggle="tooltip"
              data-bs-title="تصنيف المحادثة"
              class="icon-lg text-muted"
            ></i>
          </a>

          <div class="dropdown-menu p-0" aria-labelledby="DropdownTags">
            <div
              class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom"
            >
              <p>تصنيف المحادثة</p>
              <!-- <a href="javascript:;" class="text-muted">Clear all</a> -->
            </div>
            <div class="p-1">
              <a
                href="javascript:;"
                class="dropdown-item d-flex align-items-center py-2"
                v-for="label in labels"
                :key="label"
                @click="labelInbox(label)"
              >
                <div class="d-flex justify-content-between flex-grow-1">
                  <div class="me-4">
                    <span
                      class="badge border me-2"
                      :style="tagStyles(label.color)"
                      >{{ label.name }}</span
                    >
                  </div>
                </div>
              </a>
            </div>
          </div>

          <a
            class="me-0 me-sm-3"
            data-bs-title="تعيين"
            type="button"
            href="#"
            id="DropdownUsers"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            v-show="inbox?.assigned == 0"
          >
            <i
              data-feather="user-plus"
              data-bs-toggle="tooltip"
              data-bs-title="تعيين"
              class="icon-lg text-muted"
            ></i>
          </a>

          <a
            class="me-0 me-sm-3"
            data-bs-title="تعيين"
            type="button"
            href="#"
            id="DropdownUsers"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            v-show="inbox?.assigned == 1"
          >
            <span class="badge rounded-pill border border-primary text-primary">
              <i
                data-feather="user"
                data-bs-toggle="tooltip"
                data-bs-title="تعيين"
                class="icon-lg text-muted me-2"
              ></i>
              {{ inbox?.inbox_assigned_user?.user?.name }}</span
            >
          </a>

          <div class="dropdown-menu p-0" aria-labelledby="DropdownUsers">
            <div
              class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom"
            >
              <p>تعيين المحادثة لمستخدم</p>
              <!-- <a href="javascript:;" class="text-muted">Clear all</a> -->
            </div>
            <div class="p-1">
              <a
                href="javascript:;"
                class="dropdown-item d-flex align-items-center py-2"
                v-for="user in users"
                :key="user"
                @click="assignInbox(user)"
              >
                <div class="d-flex justify-content-between flex-grow-1">
                  <div class="me-4">
                    <p>{{ user.name }}</p>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <button
            type="button"
            data-bs-title="إنهاء المحادثة"
            data-bs-toggle="tooltip"
            class="btn btn-primary btn-icon"
            v-show="inbox?.assigned == 1"
            @click="finishInbox"
          >
            <i data-feather="check"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="chat-body my-2">
      <div class="text-center mb-3">
        <button
          type="button"
          class="btn btn-xs btn-inverse-primary btn-icon-text"
          v-show="!loading"
        >
          <i class="btn-icon-prepend" data-feather="more-horizontal"></i>
          more
        </button>

        <div v-show="loading" class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <ul class="messages" ref="chat">
        <li
          class="message-item"
          :class="{
            friend: message.message_id != 'local_id',
            me: message.message_id == 'local_id',
          }"
          v-for="message in messages"
          :key="message"
        >
          <img
            v-if="inbox?.contact.name"
            :src="
              'https:placehold.co/34x34/6571f' +
              inbox?.contact.name[0] +
              '/FFF?text=' +
              inbox?.contact.name[0]
            "
            class="img-xs rounded-circle"
            alt="avatar"
            v-show="message.message_id != 'local_id'"
          />

          <img
            v-if="!inbox?.contact.name"
            :src="'https:placehold.co/34x34/6571fu/FFF?text=UnNamed'"
            class="img-xs rounded-circle"
            alt="avatar"
            v-show="message.message_id != 'local_id'"
          />

          <img
            :src="
              'https:placehold.co/34x34/66d1d' +
              message.user?.name[0] +
              '/FFF?text=' +
              message.user?.name[0]
            "
            class="img-xs rounded-circle"
            alt="avatar"
            v-show="message.message_id == 'local_id'"
          />

          <div class="content">
            <div class="message">
              <div class="bubble" v-if="message.type == 'text'">
                <p>
                  {{ message.message }}
                </p>
              </div>

              <div class="bubble" v-if="message.type == 'template'">
                <div class="card">
                  <div class="card-header">
                    {{ message.messages_template.template.name }}
                  </div>
                  <img
                    src="https:placehold.co/300x200/6571fy/FFF?text=y"
                    class="card-img-top"
                    alt="..."
                    v-show="
                      message.messages_template.template?.template_header &&
                      message.messages_template.template?.header == 'IMAGE'
                    "
                  />

                  <div class="card-body">
                    <h5
                      class="card-title"
                      v-show="
                        message.messages_template.template?.template_header &&
                        message.messages_template.template?.header == 'TEXT'
                      "
                    >
                      {{
                        message.messages_template.template?.template_header
                          ?.value
                      }}
                    </h5>
                    {{ message.message }}
                  </div>

                  <p
                    class="card-text mx-3 mb-3"
                    v-show="message.messages_template.template?.footer_text"
                  >
                    <small class="text-muted">{{
                      message.messages_template.template?.footer_text
                    }}</small>
                  </p>
                  <div
                    class="card-footer"
                    v-if="
                      message.messages_template.template?.template_buttons &&
                      message.messages_template.template.template_buttons != []
                    "
                  >
                    <div
                      class="row mb-1"
                      v-for="button in message.messages_template.template
                        ?.template_buttons"
                      :key="button"
                    >
                      <div class="col-sm-8">
                        <button
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#newChatModal"
                          data-bs-whatever="@mdo"
                          class="btn btn-primary"
                          :class="{
                            'btn-icon-text': button.type == 'QUICK_REPLY',
                          }"
                        >
                          {{ button.name }}
                          <BIconReply
                            v-show="button.type == 'QUICK_REPLY'"
                          ></BIconReply>
                          <BIconTelephone
                            v-show="button.type == 'PHONE_NUMBER'"
                          ></BIconTelephone>
                          <BIconLink v-show="button.type == 'URL'"></BIconLink>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="bubble p-0" v-if="message.type == 'image'">
                <div class="bubble p-0" style="overflow: hidden">
                  <img
                    src="https://placehold.co/200x200/6571fM/FFF?text=A"
                    class="mx-0"
                    alt="avatar"
                  />
                </div>
              </div>

              <span class="me-1" v-show="message.message_id == 'local_id'">
                {{ message.user?.name }}
              </span>
              <span class="me-1" v-show="message.message_id != 'local_id'">
                {{ inbox?.contact.name ?? inbox?.contact.phone }}
              </span>
              <span class="me-1">
                <Timeago :datetime="message.timestamp" />
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="chat-footer d-flex">
      <div>
        <button
          type="button"
          class="btn border btn-icon rounded-circle me-2 pickiemoji"
          data-bs-toggle="tooltip"
          picki-emoji
          data-bs-title="Emoji"
        >
          <i data-feather="smile" class="text-muted pickiemoji"></i>
        </button>
      </div>
      <div class="d-none d-md-block">
        <div class="dropdown">
          <a
            type="button"
            class="btn border btn-icon rounded-circle me-2"
            id="attatchmentsMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i data-feather="paperclip" class="text-muted"></i>
          </a>
          <div class="dropdown-menu" aria-labelledby="attatchmentsMenuButton">
            <a
              class="dropdown-item d-flex align-items-center"
              href="javascript:;"
              ><i data-feather="image" class="icon-sm me-2"></i>
              <span class="">Image </span></a
            >
            <a
              class="dropdown-item d-flex align-items-center"
              href="javascript:;"
              ><i data-feather="video" class="icon-sm me-2"></i>
              <span class="">Video</span></a
            >
            <a
              class="dropdown-item d-flex align-items-center"
              href="javascript:;"
              ><i data-feather="file-text" class="icon-sm me-2"></i>
              <span class="">File</span></a
            >
            <a
              class="dropdown-item d-flex align-items-center"
              href="javascript:;"
              ><i data-feather="map-pin" class="icon-sm me-2"></i>
              <span class="">Location</span></a
            >
          </div>
        </div>
        <!-- <button
          type="button"
          class="btn border btn-icon rounded-circle me-2"
          data-bs-toggle="tooltip"
          data-bs-title="Attatch files"
        >
          <i data-feather="paperclip" class="text-muted"></i>
        </button> -->
      </div>
      <div class="d-none d-md-block dropup">
        <!-- <div class="dropdown"> -->
        <a
          type="button"
          class="btn border btn-icon rounded-circle me-2"
          id="templateMenuButton"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i data-feather="plus" class="text-muted"></i>
        </a>

        <div class="dropdown-menu" aria-labelledby="templateMenuButton">
          <a class="dropdown-item d-flex align-items-center"
            ><i data-feather="hash" class="icon-sm me-2"></i>
            <span class="">Template 1 </span></a
          >
        </div>
        <!-- </div> -->

        <!-- <div class="dropdown">
          
        </div> -->
      </div>
      <form class="search-form flex-grow-1 me-2">
        <div class="input-group">
          <textarea
            type="text"
            class="form-control flex-grow rounded-3 scroll chatformfiller"
            id="chatForm"
            style="overflow: hidden; resize: none; height: 40px"
            @input="updateValue($event.target.value)"
            ref="input"
            v-model="text_message"
            placeholder="Type a message"
          ></textarea>
        </div>
      </form>
      <div>
        <button
          type="button"
          @click="sendMessage"
          class="btn btn-primary btn-icon rounded-circle"
        >
          <i data-feather="send"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { BIconStarFill } from "bootstrap-icons-vue";
import { Timeago } from "vue2-timeago";

export default {
  components: {
    Timeago,
  },
  props: { inbox: null },
  data() {
    return {
      loading: false,
      messages: [],
      labels: [],
      users: [],
      text_message: null,
      tamplatesMeunShown: false,
    };
  },
  methods: {
    updateValue(value) {
      // adding v-model support to this reusable component
      // this.$refs.input.value = value;
      // this.text_message = value;
      if (this.$refs.input.scrollHeight < 80) {
        this.$refs.input.style.height = this.$refs.input.scrollHeight + "px";
      }
    },
    getMessages() {
      if (this.inbox == null) {
        return;
      }
      this.loading = true;
      console.log("getMessages" + this.inbox?.inbox_labels);
      this.http.get("inbox/" + this.inbox?.id + "/messages").then((res) => {
        console.log(res);
        this.loading = false;
        if (res.status) {
          this.messages = res.data;

          var container = this.$el.querySelector(".messages");
          console.log("container.scrollHeight");
          console.log(container.scrollHeight);
          container.scrollTop = container.scrollHeight;
          // var messageDisplay = this.$refs.chat;
          // messageDisplay.scrollBottom = messageDisplay.scrollHeight;
        }
      });
    },

    sendMessage() {
      if (this.text_message == null) {
        return;
      }

      var message = {
        type: "text",
        message: this.text_message,
        user_id: 1,
      };
      this.http
        .post("inbox/" + this.inbox?.id + "/create_message", message)
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.status) {
            this.text_message = null;
          }
          this.getMessages();
          // }
        });
    },
    labelInbox(label) {
      if (this.inbox == null) {
        return;
      }
      this.loading = true;
      this.http
        .post("inbox/" + this.inbox?.id + "/label", label)
        .then((res) => {
          console.log(res);
          this.loading = false;
          let random_id = Math.ceil(Math.random() * 1000000);
          const inbox_label = { id: random_id, label: label };
          console.log(inbox_label);
          if (res.status) {
            if (this.inbox.inbox_labels) {
              this.inbox.inbox_labels.push(inbox_label);
            } else {
              this.inbox.inbox_labels = [];
              this.inbox.inbox_labels.push(inbox_label);
            }
          }
          this.$emit("update_inboxes");
        });
    },

    assignInbox(user) {
      if (this.inbox == null) {
        return;
      }
      this.loading = true;
      this.http
        .post("inbox/" + this.inbox?.id + "/assigin", user)
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.status) {
            this.inbox.inbox_assigned_user = user;
          }
          this.$emit("update_inboxes");
        });
    },
    finishInbox() {
      if (this.inbox == null) {
        return;
      }
      this.loading = true;
      this.http.post("inbox/" + this.inbox?.id + "/finish", {}).then((res) => {
        console.log(res);
        this.loading = false;

        this.$emit("update_inboxes");
      });
    },

    tagStyles(color) {
      return {
        "background-color": color + "2b" + " !important",
        "border-color": color + " !important",
        color: color,
      };
    },

    // toggleTemplatestDropDown() {
    //   this.tamplatesMeunShown = !this.tamplatesMeunShown;
    // },

    // sendTemplate(template) {
    //   this.toggleTemplatestDropDown();
    // },
  },

  mounted() {
    this.getMessages();
  },
};
</script>

<style scoped>
.center-text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.chat-body {
  height: calc(100vh - 340px);
}
.scroll::-webkit-scrollbar {
  display: none;
}
</style>
