<template>
  <SideView />

  <div class="page-wrapper">
    <HeaderView />
    <div class="page-content">
      <InboxView />
    </div>
    <FooterView />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderView from "@/components/HeaderView.vue";
import SideView from "@/components/SideView.vue";
import FooterView from "@/components/FooterView.vue";
import InboxSideBar from "@/components/InboxSideBar.vue";
import InboxView from "@/components/InboxView.vue";

export default {
  name: "HomeView",
  components: {
    HeaderView,
    SideView,
    InboxSideBar,
    FooterView,
    InboxView,
  },
};
</script>

<style scoped></style>
